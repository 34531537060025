import axios from 'axios';
import infoData from './assets/informacion.json';
/* import productosData from '/assets/productos.json';      // Importar datos de productos
import categoriasData from '/assets/categorias.json';    // Importar datos de categorías
 */
class ActionProvider {

  async collectClientInfo() {
    const clientInfo = {
      browser: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      // Obtener la IP del usuario
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      clientInfo.ip = ipResponse.data.ip;
      clientInfo.country = "País no disponible";
      clientInfo.region = "Región no disponible";
      clientInfo.city = "Ciudad no disponible";
      // Obtener información geográfica basada en la IP
      /*  const geoResponse = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`);
       clientInfo.country = geoResponse.data.country_name || "País no disponible";
       clientInfo.region = geoResponse.data.state_prov || "Región no disponible";
       clientInfo.city = geoResponse.data.city || "Ciudad no disponible"; */
    } catch (error) {
      clientInfo.ip = "IP no disponible";
      clientInfo.country = "País no disponible";
      clientInfo.region = "Región no disponible";
      clientInfo.city = "Ciudad no disponible";
    }

    return clientInfo;
  }

  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.history = [];
    this.lastRequestTime = 0;  // Guarda el tiempo de la última solicitud
    this.delay = 3000;         // Tiempo de espera entre solicitudes (2 segundos)
    this.isWaiting = false;    // Evita múltiples solicitudes en espera

  }

  // Función para cargar archivos JSON de la carpeta public
  async fetchJSON(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Error cargando ${url}`);
      return await response.json();
    } catch (error) {
      console.error("Error al cargar JSON:", error);
      return null;
    }
  }


  similarity(s1, s2) {
    const set1 = new Set(s1.split(''));
    const set2 = new Set(s2.split(''));
    const intersection = new Set([...set1].filter(x => set2.has(x)));
    const union = new Set([...set1, ...set2]);
    return intersection.size / union.size;
  }

  handleInfoNombre = (keyword, ) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { nombre } = infoData;
    const botMessage = `El nombre de nuestra tienda es ${nombre}. ¿Te puedo ayudar con algo más?`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoDireccion = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { direccion } = infoData;
    const botMessage = `${direccion}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoHorarios = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { horarios } = infoData;
    const botMessage = `Horario: ${horarios}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoEnvios = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { envios } = infoData;
    const botMessage = `Envio a regiones: ${envios}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoExtranjero = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { extranjero } = infoData;
    const botMessage = `Envios al extranjero: ${extranjero}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoTrueque = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { trueque } = infoData;
    const botMessage = `Cambios: ${trueque}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoCompra = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { compra } = infoData;
    const botMessage = `${compra}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoBilling = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { billing } = infoData;
    const botMessage = `${billing}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoCorreo = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { correo } = infoData;
    const botMessage = `${correo}`;
    const message = this.createChatBotMessage(
      botMessage, {
      widget: "emailCard",
      payload: {
      }
    }
    );
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(message);
    this.handleSQL(filtro, botMessage);
  };

  handleInfoOfertas = (keyword) => {
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const filtro = keyword.toLowerCase();
    const { ofertas } = infoData;
    const botMessage =`${ofertas}`;
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleInfoDevoluciones = (keyword) => {
    const filtro = keyword.toLowerCase();
    if (!infoData) {
      this.sendErrorMessage();
      return;
    }
    const { devoluciones } = infoData;
    const botMessage = `${devoluciones}`;
    const message = this.createChatBotMessage(botMessage,
      {
        widget: "policityCard",
        payload: {
        }
      }
    );
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(message);
    this.handleSQL(filtro, botMessage);
  };

  handleProductos = (dt, keyword) => {
    // Verificar si hay productos que coinciden
    const filtro = keyword.toLowerCase();
    if (dt.length > 0) {
      const botMessage = "Aquí tienes un producto disponible que coincide con tu búsqueda:";
      dt.forEach(prod => {
        const message = this.createChatBotMessage(botMessage
          ,
          {
            widget: "productCard",
            payload: {
              id: prod.id,
              descripcion: prod.descripcion,
              valor: prod.valor,
              imagen: prod.foto1,
              link: prod.url,
              vendido : prod.vendido
            }
          }
        );
        this.updateChatbotState(message);
      });
      this.addMessageToHistory('productos', botMessage);
      this.handleSQL(filtro, botMessage);
    } else {
      const botMessage = `Lo siento, no encontré productos que coincidan con "${filtro}".`;
      const noResultsMessage = this.createChatBotMessage(botMessage);
      this.addMessageToHistory('productos', botMessage);
      this.updateChatbotState(noResultsMessage);
      this.handleSQL(filtro, botMessage);
    }
  };

  handleCategorias = (keyword) => {
    if (!this.categoriasData) {
      this.sendErrorMessage();
      return;
    }
    if (this.categoriasData.length > 0) {
      const filtro = keyword.toLowerCase();
      const botMessage = `Estas son nuestras categorías:`;
      this.categoriasData.forEach(categoria => {

        const message = this.createChatBotMessage(botMessage, {
          widget: "categoryCard",
          payload: {
            imagen: categoria.imagen,
            titulo: categoria.titulo,
            link: categoria.url
          }
        });
        this.updateChatbotState(message);
      });
      this.addMessageToHistory('categorias', botMessage);
      this.handleSQL(filtro, botMessage);
    }
  };

  // Respuesta predeterminada para consultas desconocidas
  handleUnknown = (keyword) => {
    const filtro = keyword.toLowerCase();
    const botMessage = "Lo siento, no entendí tu consulta. ¿Quieres información sobre la tienda, buscar productos o categorías?";
    this.addMessageToHistory('unknown', botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };

  handleCensored = (keyword, botMessage) => {
    const filtro = keyword.toLowerCase();
    this.addMessageToHistory('censored', botMessage);
    this.updateChatbotState(this.createChatBotMessage(botMessage));
    this.handleSQL(filtro, botMessage);
  };
  
  handleSeguimiento = (keyword) => {

    const respuestasSeguimiento = [
      "Hola, ¿necesitas información del tracking?",
      "Hola, ¿necesitas información de seguimiento?",
      "Hola, ¿necesitas información del despacho?",
      "Hola, ¿quieres seguir el despacho?"
    ];

    // Convertir el mensaje a minúsculas para comparación
    const filtro = keyword.toLowerCase();
    const botMessage = respuestasSeguimiento[Math.floor(Math.random() * respuestasSeguimiento.length)];
    // Buscar si alguna palabra clave de seguimiento está en el mensaje
 /*    const coincidencia = seguimientoKeywords.find((seguimiento) => filtro.includes(seguimiento));

    if (coincidencia) { */
    const messageRespuesta = this.createChatBotMessage(botMessage,
      {
        widget: "seguimientoCard" // Asegúrate que este widget esté registrado en `config.js`
      }
    );
    
    this.updateChatbotState(messageRespuesta);
   /*  } */
    this.addMessageToHistory(filtro, botMessage);
    this.handleSQL(filtro, botMessage);
  };

  handleSaludos = (keyword) => {

    const respuestasSaludos = [
      "Hola, buenas, ¿en qué puedo ayudarte?",
      "Buenas, ¿cómo puedo ayudarte hoy?",
      "Hola, buenas, ¿en qué puedo ayudarte hoy?",
      "Hola, buenas, ¿cómo te puedo ayudar hoy?",
      "Hola, buenas, ¿cómo te puedo ayudar",
      "Que tal, ¿en qué puedo ayudarte?",
    ];
    // Convertir el mensaje a minúsculas para comparación
    const filtro = keyword.toLowerCase();
    const botMessage = respuestasSaludos[Math.floor(Math.random() * respuestasSaludos.length)];
    const messageRespuesta = this.createChatBotMessage(botMessage);
    this.addMessageToHistory(filtro, botMessage);
    this.updateChatbotState(messageRespuesta);
    this.handleSQL(filtro, botMessage);
  };

  handleWhatsApp = (keyword) => {
    const filtro = keyword.toLowerCase();
    const botMessage = "Antes de llamarnos, contáctanos por WhatsApp.";
    this.addMessageToHistory('whatsapp', botMessage); // Guardar historial
    this.updateChatbotState(this.createChatBotMessage(botMessage, {
      widget: "whatsappWidget",
      payload: {
      },
    }
    ));
    this.handleSQL(filtro, botMessage);
  };

  handleSQL = (userMessage, botMessage = null, retryCount = 0) => {
    const currentTime = Date.now();
    /* console.log("➡️ Enviando mensaje:", userMessage); */

    if (this.isWaiting) {
      console.warn("⚠️ Esperando respuesta previa, evitando solicitud duplicada.");
      return;
    }
  
    const timeSinceLastRequest = currentTime - this.lastRequestTime;
  
    if (timeSinceLastRequest < this.delay) {
      const waitTime = this.delay - timeSinceLastRequest;
      this.isWaiting = true;
      setTimeout(() => {
        this.sendRequest(userMessage, botMessage, retryCount);
        this.isWaiting = false;
      }, waitTime);
    } else {
      this.sendRequest(userMessage, botMessage, retryCount);
    }
  
    this.lastRequestTime = Date.now();
  };
  
  sendRequest = async (userMessage, botMessage = null, retryCount = 0) => {
    try {
      const clientInfo = await this.collectClientInfo();
      /* console.log("📡 Enviando al servidor:", { userMessage, botMessage, clientInfo }); */
  
      await axios.post(
        'https://mp.toytek.cl/chatAI/guardar-historial',
        { userMessage, botMessage, clientInfo },
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          withCredentials: true,
        }
      );
      this.lastRequestTime = Date.now();
    } catch (error) {
      console.error('❌ Error al comunicarse con el backend:', error.message);
  
      const errorMessage = this.createChatBotMessage(
        "Lo siento, algo salió mal. Intenta más tarde."
      );
      this.updateChatbotState(errorMessage);
    } 
  };


  // Guarda mensaje del usuario y respuesta del bot en el historial local
  addMessageToHistory = (userMessage, botMessage) => {
    this.history.push({
      userMessage,
      botMessage,
      timestamp: new Date().toLocaleString(),
    });
  };

  // Función para actualizar el estado del chatbot
  updateChatbotState(message) {
    this.setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  }

  // Método para manejar errores
  handleError = (error) => {
    const errorMessage = this.createChatBotMessage(
      `${error.message || 'No se pudo procesar tu solicitud.'}`
    );
    this.updateChatbotState(errorMessage);
  }

}
export default ActionProvider;