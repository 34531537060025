import React from "react";

const SeguimientoCard = () => {
  return (
    <div style={{ 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      padding: '10px', 
      marginBottom: '10px', 
      maxWidth: '250px' 
    }}>
      <p>Consulta el estado de tu pedido aquí:</p>
      <a
        className="btn-primary"
        href="https://www.starken.cl/seguimiento"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "inline-block",
          padding: "8px 16px",
          backgroundColor: "#007bff",
          color: "#fff",
          borderRadius: "5px",
          textDecoration: "none",
          fontWeight: "bold"
        }}
      >
        Seguimiento de Pedido
      </a>
    </div>
  );
};

export default SeguimientoCard;
