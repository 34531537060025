import { createChatBotMessage } from 'react-chatbot-kit';
import ProductCard from './ProductCard';
import CategoryCard from './CategoryCard';
import WelcomeMessage from './WelcomeMessage';
import PolicityCard from './PoliticyCard';
import EmailCard from './EmailCard';
import SeguimientoCard from "./SeguimientoCard";
import WhatsAppCardfrom from "./WhatsAppCard";/* 
import ActionProvider from './ActionProvider'; */
const BotAvatar = () => {
  return (
    <div className="custom-bot-avatar">
      <img 
        src="/assets/logo/roboti.png"  
        alt="Bot Avatar"
        className="avatar-image"
      />
    </div>
  );
};

const initialMessages = [
  createChatBotMessage("¡Bienvenido!", {
    widget: "welcomeWidget"
  })
];

const config = {
  initialMessages: initialMessages,
  customComponents: {
    header: () => (
      <div style={{ backgroundColor: '#4a90e2', padding: '10px', color: '#fff', fontWeight: 'bold' , fontSize: '14px' }}>
        Asistente Virtual TOYTEK
      </div>
    ),
    botAvatar: (props) => <BotAvatar {...props} />, 
  },

  widgets: [
    {
      widgetName: "productCard",
      widgetFunc: (props) => <ProductCard {...props.payload} />,
    },
    {
      widgetName: "categoryCard",
      widgetFunc: (props) => <CategoryCard {...props.payload} />,
    },
    {
      widgetName: "policityCard",
      widgetFunc: (props) => <PolicityCard {...props} />,
    },
    {
      widgetName: "emailCard",
      widgetFunc: (props) => <EmailCard {...props} />,
    },
    {
      widgetName: "welcomeWidget",
      widgetFunc: (props) => <WelcomeMessage {...props} />,
    },
    {
      widgetName: "seguimientoCard",
      widgetFunc: (props) => <SeguimientoCard {...props} />,
    },{
        widgetName: "whatsappWidget",
        widgetFunc: (props) => <WhatsAppCardfrom {...props.payload} />,
        
      },
  ],
};

export default config;