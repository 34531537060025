import React, { useState } from 'react';

const EmailCard = () => {
  const [copiado, setCopiado] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('hola@toytek.cl')
      .then(() => {
        setCopiado(true);
        setTimeout(() => setCopiado(false), 2000); // Reinicia el texto después de 2 segundos
      })
      .catch(err => console.error('Error al copiar el correo:', err));
  };

  return (
    <div style={{ 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      padding: '10px', 
      marginBottom: '10px', 
      maxWidth: '250px' 
    }}>
    <p>📧 <a id="email" href="mailto:hola@toytek.cl" value="hola@toytek.cl" rel="noopener noreferrer" style={{fontSize: '16px', textDecoration: 'none'}}>hola@toytek.cl</a></p>
      {/* Botón para copiar el email */}
      <button 
        onClick={handleCopyEmail}
        style={{
          display: 'block',
          marginTop: '5px',
          padding: '8px',
          backgroundColor: copiado ? '#28a745' : '#007BFF',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
      >
        {copiado ? 'Copiado!' : 'Copiar Email'}
      </button>
    </div>
  );
};

export default EmailCard;
