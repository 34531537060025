import React from 'react';
const PoliticyCard = () => {
  return (
    <div style={{ 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      padding: '10px', 
      marginBottom: '10px', 
      maxWidth: '250px' 
    }}>
    <p>Puedes consultar aquí en el Link sobre nuestros pagos, despachos y devoluciones</p>
      <a className="btn-primary" href={'https://toytek.cl/pages/pagos-y-despachos'} target="_blank" rel="noopener noreferrer">ir a Pagos, despachos, devoluciones.</a>
    </div>
  );
};

export default PoliticyCard;
