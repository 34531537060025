import React from 'react';
import ReactDOM from 'react-dom/client';
import ChatbotWidget from './chatbot.js';
import './chatbot.css';

const root = ReactDOM.createRoot(document.getElementById('root-chatbot-widget'));
root.render(
  <React.StrictMode>
    <ChatbotWidget />
  </React.StrictMode>
);  