import React from 'react';
const CategoryCard = ({ imagen, titulo, link }) => {
  return (
    <div style={{ 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      padding: '10px', 
      marginBottom: '10px', 
      maxWidth: '250px' 
    }}>
      <img 
        src={imagen.replace('../', 'https://toytek.cl/')} 
        alt={titulo} 
        style={{ width: '100%', borderRadius: '8px' }} 
      />
      <h4 style={{fontSize: '12px'}}>{titulo}</h4>
      <p>Para regalar o coleccionar.</p>
      <a className="btn-primary" href={'https://toytek.cl'+link} target="_blank" rel="noopener noreferrer">Ver categoría</a>
    </div>
  );
};

export default CategoryCard;
