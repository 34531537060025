import axios from 'axios';

class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  async handleAnalyzeMessage(message) {
    try {
      const res = await axios.post('https://mp.toytek.cl/chatAI/analyze', { message },
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          withCredentials: true,
        });

      if (res.data.category) {
        switch (res.data.category) {
          case 'whatsapp':
            this.actionProvider.handleWhatsApp(message);
            break;
          case 'saludos':
            this.actionProvider.handleSaludos(message);
            break;
          case 'tracking':
            this.actionProvider.handleSeguimiento(message);
            break;
          case 'nombre':
            this.actionProvider.handleInfoNombre(message);
            break;
          case 'direccion':
            this.actionProvider.handleInfoDireccion(message);
            break;
          case 'horario':
            this.actionProvider.handleInfoHorarios(message);
            break;
          case 'envios':
            this.actionProvider.handleInfoEnvios(message);
            break;
          case 'extranjero':
            this.actionProvider.handleInfoExtranjero(message);
            break;
          case 'trueque':
            this.actionProvider.handleInfoTrueque(message);
            break;
          case 'compra':
            this.actionProvider.handleInfoCompra(message);
            break;
          case 'correo':
            this.actionProvider.handleInfoCorreo(message);
            break;
          case 'ofertas':
            this.actionProvider.handleInfoOfertas(message);
            break;
          case 'billing':
            this.actionProvider.handleInfoBilling(message);
            break;
          case 'devoluciones':
            this.actionProvider.handleInfoDevoluciones(message);
            break;
          case 'categorias':
            this.actionProvider.handleCategorias(message);
            break;
          case 'producto':
            this.actionProvider.handleProductos(res.data.results, message);
            break;
          case 'censored':
          this.actionProvider.handleCensored(message, res.data.message);
          break;
          default:
            this.actionProvider.handleUnknown(message);
            break;
        }
      } else {
        this.actionProvider.handleUnknown(message);
      }
    } catch (err) {
      console.error('Error al conectar con el servidor:', err.message);
      this.actionProvider.handleError('No pudimos procesar tu mensaje.');
    }
  }

  parse(message) {
    if (message.trim()) {
      this.handleAnalyzeMessage(message.toLowerCase());
    } else if (!message.trim()) { return }
  }

}

export default MessageParser;
