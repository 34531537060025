import React, { useState, useEffect } from "react";
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import './chatbot.css';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import { FiX } from "react-icons/fi";  

function ChatbotWidget () {
  const [isOpen, setIsOpen] = useState(false);
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const CustomPlaceholder = () => {
  useEffect(() => {
    const inputField = document.querySelector('.react-chatbot-kit-chat-input');
    if (inputField) {
      inputField.placeholder = 'Escribe tu mensaje aquí...';
    }
  }, []);
  return null;
};

  return (
    <div>
      {/* Botón flotante estilo burbuja */}
      <button 
        onClick={toggleChat} 
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#1976d2",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        
          border: "none",
          cursor: "pointer",
          zIndex: 1000,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)"
        }}
      >
        {isOpen ? <FiX size={30} color="#fff" /> :  <img 
        src="/assets/logo/roboti.png"  
        alt="Bot Avatar"
        className="avatar-image-custom" 
      />}
      </button>

      {/* Chatbot minimizable */}
      {isOpen && (
        <div style={{
          position: "fixed",
          bottom: "90px",
          right: "20px",
          width: "350px",
          height: "450px",
          zIndex: 999
        }} >
          <Chatbot 
            config={config} 
            messageParser={MessageParser} 
            actionProvider={ActionProvider}
          />
          <CustomPlaceholder />
          </div>
      )}
    </div>
  );
}

export default ChatbotWidget;