import React from 'react';

const nf = new Intl.NumberFormat("es-CL");

const ProductCard = ({ id, descripcion, valor, imagen, link, vendido }) => {

  const imagen2 = imagen.includes('_i.JPG') || imagen.includes('png')
    ? imagen
    : `${imagen}_i.JPG`;
  const precio = nf.format(valor);
  return (
    <div style={{ 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      padding: '10px', 
      marginBottom: '10px', 
      maxWidth: '250px', 
      backgroundColor: `${vendido === 'SI' ? '#000' : '#fff'}`
    }}>
      
      <img 
        src={imagen2.replace('../', 'https://toytek.cl/')} 
        alt={descripcion} 
        style={{ width: '100%', borderRadius: '8px' }} 
      />
      <h4 className={`${vendido === 'SI' ? 'color-h4-vendido' : 'color-h4'}`} style={{fontSize: '12px'}}>{descripcion} - {id}</h4>
      {vendido === 'SI' ? <p style={{color: `${vendido === 'SI' ? '#fff' : '#000'}`, fontSize: '20px'}}>VENDIDO</p> : <p>$ {precio}.-</p>}
      
      <a className="btn btn-primary" style={{color: `${vendido === 'SI' ? '#fff' : '#000'}`, fontSize: '14px'}} href={`https://toytek.cl/products/${link}`} target="_blank" rel="noopener noreferrer">Ver producto</a>
    </div>
  );
};

export default ProductCard;